const SettingsMenu = [
  {
    heading: 'menu',
    route: '/trainee',
    pages: [
      // {
      //   // heading: 'Trainee Details',
      //   // route: '/trainee/trainee-details',
      //   // fontIcon: 'fa-users',
      //   // svgIcon: 'media/icons/duotune/general/gen025.svg',
      // },
      {
        heading: 'New Trainee',
        route: '/trainee/new-trainee',
        fontIcon: 'fa-users',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
      },
      {
        heading: 'Trainee Info',
        route: '/trainee/update',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
        fontIcon: 'fa-refresh',
      },
      {
        heading: 'Trainee List',
        route: '/trainee/show-trainee',
        fontIcon: 'fa-user',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
      },
      {
        heading: 'Search Trainee',
        route: '/trainee/search-trainee',
        fontIcon: 'fa-search',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
      },
      {
        sectionTitle: 'Income Certificate',
        route: '/trainee/income-certificate',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
        fontIcon: 'bi-app-indicator',
        sub: [
          {
            heading: 'Required Income Certificate',
            route: '/trainee/income-certificate/required',
          },
          {
            heading: 'Upload Income Certificate',
            route: '/trainee/income-certificate/upload',
          },
          {
            heading: 'Income Certificate Status',
            route: '/trainee/income-certificate/status',
          },
        ],
      },
    ],
  },
];

export default SettingsMenu;
